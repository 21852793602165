<template>
    <div class="flex-start-wrap filter-select-list" v-show="selectList.length>0">
        <el-tag @close="tagClose(item,index)" closable class="mr-1" v-for="(item,index) in selectList" :key="index">
            <span class="filter-select-label">{{item.label}}：</span>
            <span class="filter-select-value">{{item.value}}</span>
        </el-tag>
    </div>
</template>

<script>
  export default {
    props: {
      selectList:{
        type:Array,
        default(){
          return []
        }
      },
      listQuery:{
        type:Object,
      }
    },
    methods: {
      tagClose(item,index){
         this.selectList.splice(index,1)
         this.listQuery[item.keyStr] = ''
        this.$emit('handleFilter')
      }
    }
  }
</script>

<style scoped>

</style>
