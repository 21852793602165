<template>
  <el-dialog
    title="导出"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form"
      label-width="100px"
    />
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import { ExportPrivacyField } from '@/api/dictLibrary/dictLibrary'

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      groupOptions: [],
      enterpriseId: 1,
      form: {
        groupId: '',
      },
    }
  },
  methods: {
    dialogOpen() {
    },
    dialogClose() {
      this.form = {
        groupId: '',
      }
      this.$refs.form.resetFields()
    },
    submitForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.form.validate(valid => {
        // eslint-disable-next-line no-empty
        if (valid) {
          ExportPrivacyField()
          this.dialogVisible = false
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style>
  .assest-save-form{
    padding-right: 10px;
  }
  .assest-save-form .el-icon-question{
     position: absolute;
     top: 14px;
    right: -20px;
   }
</style>
