<template>
  <div class="privacy-field-content">
    <b-row class="match-height">
      <b-col
        xl="3"
        lg="3"
        md="3"
      >
        <b-card
          id="tree-card"
          class="box-card"
        >
          <div class="flex-between mb10">
            <h4>
              隐私字段分类
            </h4>
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="openClassSaveDialog(1,null)"
            >
              新增一级分类
            </el-button>
          </div>
          <div :style="{maxHeight: pageH -250 + 'px',overflowY: 'scroll'}">
            <el-tree
              ref="elTree"
              :props="defaultProps"
              :highlight-current="true"
              :data="treeData"
              :default-expanded-keys="expandKeys"
              node-key="id"
              :expand-on-click-node="false"
              @current-change="treeNodeClick"
            >
              <span
                slot-scope="{ node, data }"
                class="field-tree-node"
              >
                <span>{{ node.label }}</span>
                <span class="eltree-operate tree-operate">
                  <el-link
                    v-show="node.level < 4"
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="openClassSaveDialog(1,data)"
                  >
                    <el-tooltip
                      content="新增子类"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-circle-plus-outline" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    class="mr10"
                    type="primary"
                    @click="openClassSaveDialog(2,data)"
                  >
                    <el-tooltip
                      content="修改"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-edit" />
                    </el-tooltip>
                  </el-link>
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="deleteClass(data,node)"
                  >
                    <el-tooltip
                      content="删除"
                      placement="top"
                      effect="light"
                    >
                      <i class="el-icon-delete" />
                    </el-tooltip>
                  </el-link>
                </span>
              </span>
            </el-tree>
          </div>
        </b-card>
      </b-col>
      <b-col
        xl="9"
        lg="9"
        md="9"
      >
        <b-card class="box-card">
          <div class="filter-wrap flex-between">
            <SearchFilter
              :SearchStr.sync="listQuery.SearchStr"
              @handleFilter="getList"
            />
            <div
              class="text-right"
              style="margin-bottom: 10px"
            >
              <el-button
                class="mr10"
                size="small"
                type="primary"
                icon="el-icon-upload"
                @click="openFieldUploadDialog"
              >
                导入
              </el-button>
              <el-button
                class="mr10"
                size="small"
                type="primary"
                icon="el-icon-download"
                :loading="exportLoading"
                @click="exportPrivacyField"
              >
                导出
              </el-button>
              <el-button
                type="primary"
                size="small"
                icon="el-icon-plus"
                @click="openFieldSaveDialog(1,null)"
              >
                新增
              </el-button>
            </div>
          </div>
          <FilterSelectList
            :list-query.sync="listQuery"
            :select-list.sync="selectList"
            @handleFilter="getList"
          />
          <el-table
            v-if="tableInit"
            v-loading="listLoading"
            fit
            border
            :max-height="pageH"
            highlight-current-row
            :data="tableData"
            stripe
            style="width: 100%"
            @sort-change="sortChange"
          >
            <el-table-column
              prop="fieldName"
              label="字段名称"
            />
            <el-table-column
              prop="fieldEnName"
              label="英文名称"
            />
            <el-table-column
              prop="className"
              :show-overflow-tooltip="true"
              label="类型"
            />
            <el-table-column
              width="120"
              sortable
              prop="sensitive"
              label="是否敏感"
            >
              <template
                slot="header"
                slot-scope="scope"
              >
                <TableHeaderFilterDropdown
                  :key-str="'sensitive'"
                  :select-list.sync="selectList"
                  :label="'是否敏感'"
                  :value.sync="listQuery.sensitive"
                  :dropdown-options="sensitiveOption"
                  @handleFilter="handleFilter"
                />
              </template>
              <template slot-scope="{row}">
                {{ row.sensitive ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="fieldLabelList"
              :show-overflow-tooltip="true"
              label="标签"
            >
              <template slot="header">
                <TableHeaderFilterDropdown
                  :key-str="'labelId'"
                  :select-list.sync="selectList"
                  :label="'标签'"
                  :value.sync="listQuery.labelId"
                  :dropdown-options="labelOptions"
                  @handleFilter="getList"
                />
              </template>
              <template slot-scope="{row}">
                {{ row.labelName }}
              </template>
            </el-table-column>
            <el-table-column
              width="130"
              prop="securityLevel"
              sortable
              label="安全级别"
            >
              <template
                slot="header"
                slot-scope="scope"
              >
                <TableHeaderFilterDropdown
                  :key-str="'securityLevel'"
                  :select-list.sync="selectList"
                  :label="'安全级别'"
                  :value.sync="listQuery.securityLevel"
                  :dropdown-options="securityLevelOptions"
                  @handleFilter="handleFilter"
                />
                <el-tooltip
                  effect="light"
                  placement="top"
                >
                  <div
                    slot="content"
                    style="whiteSpace: pre-line"
                  >数据安全级别从高到低依次为5级、4级、3级、2级和1级</div>
                  <i
                    class="el-icon-info"
                    style="font-size: 14px;margin-left: 2px;width:auto;"
                  />
                </el-tooltip>
              </template>
              <template slot-scope="{row}">
                {{ row.securityLevel ? row.securityLevel + '级' : '' }}
              </template>
            </el-table-column>
            <el-table-column
              prop="remark"
              label="备注"
            />
            <el-table-column
              label="操作"
              width="80px"
            >
              <template slot-scope="{row}">
                <el-link
                  :underline="false"
                  class="mr10"
                  type="primary"
                  @click="openFieldSaveDialog(2,row)"
                >
                  <el-tooltip
                    content="修改"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-edit" />
                  </el-tooltip>
                </el-link>
                <el-link
                  :underline="false"
                  type="primary"
                  @click="deleteField(row)"
                >
                  <el-tooltip
                    content="删除"
                    placement="top"
                    effect="light"
                  >
                    <i class="el-icon-delete" />
                  </el-tooltip>
                </el-link>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.pageSize"
            @pagination="getList"
          />
        </b-card>
      </b-col>

    </b-row>
    <fieldClassSave
      ref="fieldClassSave"
      @addTreeNode="addTreeNode"
      @updateTreeNode="updateTreeNode"
    />
    <privacyFieldSave
      ref="privacyFieldSave"
      @getList="getList"
    />
    <privacyFieldUpload
      ref="privacyFieldUpload"
      @getAllClass="getAllClass"
    />
    <privacyFieldExport ref="privacyFieldExport" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { success, error } from '@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import {
  DeleteClassById, DeleteFieldById, ExportPrivacyField,
  GetAllClass, GetLabelOptions,
  GetPrivacyFieldListByPage,
} from '@/api/dictLibrary/dictLibrary'
import pagination from '@core/components/pagination/Pagination.vue'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown.vue'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import SearchFilter from '@core/components/search-filter/SearchFilter.vue'
import fieldClassSave from './fieldClassSave.vue'
import privacyFieldSave from './PrivacyFieldSave.vue'
import privacyFieldUpload from './PrivacyFieldUpload.vue'
import privacyFieldExport from './PrivacyExport.vue'

export default {
  components: {
    SearchFilter,
    pagination,
    fieldClassSave,
    privacyFieldSave,
    privacyFieldUpload,
    privacyFieldExport,
    TableHeaderFilterDropdown,
    FilterSelectList,
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      tableInit: true,
      treeData: [],
      tableData: [],
      expandKeys: [],
      selectList: [],
      labelOptions: [],
      listQuery: {
        page: 1,
        pageSize: 10,
        gradeId: 0,
        labelId: 0,
        classId: 0,
        SearchStr: '',
        securityLevel: 0,
        sensitive: undefined,
        orderBy: {
          securityLevel: '',
          sensitive: '',
        },
      },
      sensitiveOption: [
        { label: '是', value: true },
        { label: '否', value: false },
      ],
      securityLevelOptions: [
        { label: '1级', value: 1 },
        { label: '2级', value: 2 },
        { label: '3级', value: 3 },
        { label: '4级', value: 4 },
        { label: '5级', value: 5 },
      ],
      total: 0,
      currentNode: {},
      currentClassData: {},
      optClassData: {},
      exportLoading: false,
      listLoading: false,
    }
  },
  created() {
    this.getLabelOptions()
  },
  mounted() {
    this.getAllClass()
  },
  methods: {
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'desc', ascending: 'asc' }
      this.listQuery.orderBy[column.prop] = orderObj[order]
      this.getList()
    },
    getAllClass() {
      GetAllClass().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.treeData = resData.data
          this.getList()
        }
      })
    },
    getLabelOptions(params) {
      this.labelOptions = []
      GetLabelOptions(params).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(l => {
            this.labelOptions.push({ label: l.labelName, value: l.id })
          })
        }
      })
    },
    getList() {
      this.listLoading = true
      const listQuery = {
        page: this.listQuery.page,
        pageSize: this.listQuery.pageSize,
        gradeId: this.listQuery.gradeId ? this.listQuery.gradeId : 0,
        classId: this.listQuery.classId ? this.listQuery.classId : 0,
        labelIds: this.listQuery.labelId ? [this.listQuery.labelId] : [],
        SearchStr: this.listQuery.SearchStr,
        orderBy: this.listQuery.orderBy,
        securityLevel: this.listQuery.securityLevel ? this.listQuery.securityLevel : 0,
        // sensitive: this.listQuery.sensitive ? true : false,
        sensitive: this.listQuery.sensitive ? this.listQuery.sensitive : undefined,
      }
      GetPrivacyFieldListByPage(listQuery).then(res => {
        this.listLoading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
      }).catch(() => {
        this.listLoading = false
      })
    },
    addTreeNode(data) {
      if (data.parentId === 0) { // 添加一级分类
        this.treeData.push(data)
      } else { // 添加子分类
        if (!this.optClassData.children) {
          this.$set(this.optClassData, 'children', [])
        }
        this.optClassData.children.push(data)
      }
    },
    updateTreeNode(data) {
      this.optClassData.label = data.className
      this.optClassData.className = data.className
    },
    treeNodeClick(data, node) {
      this.currentClassData = data
      this.currentNode = node
      this.$refs.privacyFieldSave.form.classId = data.id
      if (this.listQuery.classId === data.id) {
        this.listQuery.classId = 0
        this.$refs.elTree.setCurrentKey(null)
      } else {
        this.listQuery.classId = data.id
      }
      this.handleFilter()
    },
    openClassSaveDialog(type, data) {
      this.$refs.fieldClassSave.dialogVisible = true
      if (type === 2) {
        this.$refs.fieldClassSave.form.id = data.id
        this.$refs.fieldClassSave.form.parentId = data.parentId
        this.$refs.fieldClassSave.form.className = data.label
      } else if (data) {
        this.$refs.fieldClassSave.form.parentId = data.id
        this.$refs.fieldClassSave.form.parentName = data.label
      } else {
        this.$refs.fieldClassSave.form.parentId = 0
      }
      this.optClassData = data
    },
    openFieldSaveDialog(type, data) {
      this.$refs.privacyFieldSave.dialogVisible = true
      if (type === 2) {
        this.$refs.privacyFieldSave.form.id = data.id
        this.$refs.privacyFieldSave.form.classRelationId = data.classRelationId
      } else {
        this.$refs.privacyFieldSave.form.classId = this.currentClassData.id
      }
      this.$refs.privacyFieldSave.init()
    },
    openFieldUploadDialog() {
      this.$refs.privacyFieldUpload.dialogVisible = true
    },
    deleteClass(data, node) {
      this.$confirm('是否确认删除分类吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteClassById({ id: data.id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            const { parent } = node
            const children = parent.data.children || parent.data
            const index = children.findIndex(d => d.id === data.id)
            children.splice(index, 1)
            success(res.data.msg)
            this.getList()
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    deleteField(data) {
      this.$confirm('是否确认删除字段吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteFieldById({ id: data.id, classId: data.classId }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.getList()
            success(res.data.msg)
          } else {
            error(res.data.msg)
          }
        })
      })
    },
    exportPrivacyField() {
      this.exportLoading = true
      ExportPrivacyField().then(() => {
        this.exportLoading = false
      })
    },
  },
}
</script>

<style>
.app-content {
  height: 80%;
}
.content-wrapper {
  height: 85%;
}
.content-body {
  height: 80%;
}
.privacy-field-content {
  /*width: 100%;*/
  /*height: 100%;*/
  /*display: flex;*/
  /*justify-content: space-between;*/
}
.privacy-field-content .box-card {
  /*height: 85%;*/
}

.field-class-tree-container {
  /*width: 20%;*/
  /*height: 100%;*/
  /*overflow-y: auto;*/
  /*overflow-x: scroll;*/
}
.field-class-tree-container .box-card {
  /*width: 100%;*/
  /*height: 85%;*/
  /*overflow: scroll;*/
}
.field-class-tree-container .box-card .el-card__body {
  /*height: 100%;*/
  /*width: 100%;*/
}
.field-class-tree-container .el-tree {
  /*width: 100%;*/
  /*height: 100%;*/
  /*min-width: 100%;*/
  /*display: inline-block!important;*/
}
.field-class-tree-container .el-tree .el-tree-node > .el-tree-node__children {
  /*overflow: visible;*/
}
.field-list-container {
  /*width: 79%;*/
  /*height: 100%;*/
  /*overflow: scroll;*/
}
.field-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding-right: 8px;
}
.group-select .el-select-dropdown__wrap {
  /*max-height: 274px !important;*/
  /*overflow-y: scroll !important;*/
}
.tree-operate {
  position: absolute;
  right: 0px;
}
</style>
