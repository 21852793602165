import { get, post } from '@/libs/axios'

export const UploadFile = params => post('/system/settings/UploadFile', params)
export const AccessTempFileByPath = params => get('/system/AccessTempFileByPath', params)
export const queryDeptOptions = () => get('/system/dept/QueryDeptOptions')
export const queryUserByDept = deptId => get(`/system/user/QueryUserByDept?deptId=${deptId}`)
export const queryUserOptions = () => get(`/system/user/QueryUserOptions`)

// 查询当前企业短信设置
export const GetSmsSettings = () => get(`/system/sms/GetSmsSettings`)
// 删除短信配置
export const DeleteSmsSettings = id => post(`/system/sms/DeleteSmsSettings`, id)
// 更新或添加短信配置
export const saveOrUpdateSms = params => {
  const submitUrl = params.id ? '/system/sms/UpdateSmsSettings': '/system/sms/CreateSmsSettings'
  return post(submitUrl, params)
}

// 查询当前企业邮箱设置
export const GetEmailSettings = () => get(`/system/email/GetEmailSettings`)
// 删除邮箱配置
export const DeleteEmailSettings = id => post(`/system/email/DeleteEmailSettings`, id)
// 更新或添加邮箱配置
export const saveOrUpdateEmail = params => {
  const submitUrl = params.id ? '/system/email/UpdateEmailSettings': '/system/email/CreateEmailSettings'
  return post(submitUrl, params)
}

export function CurrUserInfo() {
  return get('/sys/user/curr_info')
}
