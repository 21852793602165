<template>
    <el-dropdown class="table-header-dropdown">
        <span class="header-dropdown-title">{{label}}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
            <VuePerfectScrollbar class="dropdown-item-wrap">
                <el-dropdown-item @click.native="dropdownClick(item,index)" v-for="(item,index) in dropdownOptions" :key="index">{{item.label}}</el-dropdown-item>
            </VuePerfectScrollbar>

        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  export default {
    components: { VuePerfectScrollbar },
    props: {
      label:{
        type:String
      },
      keyStr:{
        type:String
      },
      value:{
        type:Number | String
      },
      selectList:{
        type:Array,
        default(){
          return []
        }
      },
      dropdownOptions:{
        type:Array,
        default(){
          return []
        }
      }
    },
    created() {
      if(this.value){
        setTimeout(()=>{
          var label = ''
          this.dropdownOptions.forEach(d=>{
            if(d.value === this.value){
              label = d.label
            }
          })
          this.selectList.push({
            label: this.label,
            value:  label,
            keyStr: this.keyStr
          })
        },500)
      }
    },
    methods: {
      dropdownClick(item){
        this.$nextTick(()=>{
          this.$emit('update:value', item.value)
          if(this.value){
            this.selectList.forEach(s=>{
              if(s.label === this.label){
                s.value = item.label
              }
            })
          }else{
            this.selectList.push({
              label: this.label,
              value: item.label,
              keyStr: this.keyStr
            })
          }
          this.$emit('handleFilter')
        })

      }
    }
  }
</script>

<style scoped>
 .dropdown-item-wrap{
     max-height: 300px;
     /*overflow-y: scroll;*/
     /*overflow-x: hidden;*/
 }
</style>
